import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { VscDebugBreakpointData } from 'react-icons/vsc'
import { ButtonContent } from "../global/boton/ButtonContent";

function ServHome() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <>
            <div className="w-full h-auto  md:flex block justify-center px-20 my-16">
                <div className=" w-full lg:w-[40%] lg:flex col justify-center items-center">
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        spaceBetween={50}
                        slidesPerView={1}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        {
                            rpdata?.gallery?.slice(2, 13)?.map((item, index) => {
                                return (
                                    <SwiperSlide key={index} className="w-[450px]  rounded-full h-[250px] lg:h-[450px] bg-center bg-cover border-white border-[6px] shadow-xl" style={{ backgroundImage: `url("${item}")` }} ></SwiperSlide>
                                )
                            })
                        }
                        ...
                    </Swiper>
                </div>
                <div className=" w-full lg:w-[60%] flex flex-col justify-center items-center lg:pl-28 lg:pr-10 px-6 lg:-ml-24 bg-white bg-opacity-90 rounded-3xl relative">
                    <div className="w-full flex flex-col justify-center lg:px-8 px-4 ">
                        <h1 className="text-[#347ebe] ">
                            Sale Of Birds
                        </h1>
                        <p className="text-black lg:text-start text-center">{rpdata?.dbAbout?.[2]?.text}</p>
                        <ul className='grid grid-cols-1 md:grid-cols-2 pb-5'>
                            {
                                rpdata?.dbAbout?.[2].list.map((item, index) => {
                                    return (

                                        <li key={index} className="py-2 flex items-center">
                                            <VscDebugBreakpointData />
                                            <span className="pl-2">{item}</span>
                                        </li>
                                    )
                                })

                            }
                        </ul>
                        <p className="text-black lg:text-start text-center">{rpdata?.dbAbout?.[3]?.text}</p>
                        <div className="flex justify-center md:block">
                            <ButtonContent btnphone={rpdata?.dbPrincipal?.phones[0]?.phone} />
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default ServHome;
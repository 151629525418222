import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      {/* <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.gallery?.[2]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.gallery?.[8]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.gallery?.[4]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.gallery?.[9]}")` }}
        ></figure>

      </div> */}
      <div className="w-full h-auto  md:flex block justify-center pt-[250px] pb-[50px] md:pb-[100px] md:pt-[300px] bg-gradient-to-b from-[rgba(54,136,210,1)] to-[rgba(22,74,121,1)] fesbg2 px-10">
        <div className=" w-full lg:w-[30%] lg:flex col justify-center items-center">
          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            navigation
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
          >
            {
              rpdata?.gallery?.slice(2, 13)?.map((item, index) => {
                return (
                  <SwiperSlide key={index} className="w-[450px]  rounded-full h-[250px] lg:h-[450px] bg-center bg-cover border-white border-[6px] shadow-xl" style={{ backgroundImage: `url("${item}")` }} ></SwiperSlide>
                )
              })
            }
            ...
          </Swiper>
        </div>
        <div className=" w-full lg:w-[70%] flex flex-col justify-center items-center lg:pl-28 lg:pr-10 px-6 lg:-ml-24 bg-white bg-opacity-90 rounded-3xl relative">
          <div className="w-full flex flex-col justify-center items-center">
            <h1 className="text-[#347ebe] text-center">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <p className="text-black text-center">{rpdata?.dbValues?.[1].description}</p>
            <div className="flex justify-center md:block">
            <ButtonContent btnphone={rpdata?.dbPrincipal?.phones[0]?.phone} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
